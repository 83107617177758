<template lang="pug">
q-header.desktop-header#header(itemscope itemtype="http://schema.org/WPHeader")
    meta(itemprop="headline" content="eTabl.ru")
    button-a(v-if="isActivAccessibilityPanel" isVisiblePanel="true")
    .wrapper.row.justify-between.items-center.relative-position.top
        q-card.q-pa-lg.bg-secondary.b-radius-16(
            v-if="showPlash && !userAuthorized"
            :style="fixPlash ? 'z-index: 9999; position: absolute; top: 104px' : 'z-index: 9999; position: fixed; top: 0'")
            .row.text-weight-medium.items-center
                q-icon(
                    name="etabl:location-marker"
                    color="positive"
                    size="24px"
                ).q-mr-sm
                .text-primary.q-mr-xs
                    | Ваш город
                city-form(
                    :header="false"
                    home
                )
                .text-positive 
                    | ?
            .row.q-mt-sm
                .q-mr-md
                    base-btn(
                        width="120"
                        height="30"
                        bradius="16"
                        label="Да, я здесь"
                        font_size="13"
                        @click="accept"
                    )
                city-form(
                    :header="false"
                    button 
                )
        .col-6
            q-carousel(
                v-if="homePage || error404"
                style="min-height: 56px"
                swipeable
                transition-prev="jump-right"
                transition-next="jump-left"
                animated=""
                :arrows="false"
                v-model="slide"
                infinite
                :autoplay="7000"
            )
                q-carousel-slide(
                    :name="1"
                )
                    a(href="/catalog-page?freeDelivery=true")
                        img.full-width(src="interface/top-slider/free-delivery.webp")
                q-carousel-slide(
                    :name="2"
                )
                    a(href="/certificates-article")
                        img.full-width(src="interface/top-slider/fss-cert.png")
                q-carousel-slide(
                    :name="3"
                )
                    a(href="/recipes-article")
                        img.full-width(src="interface/top-slider/recipes.webp")
        .col-6.flex.justify-end.items-center
            a.link(href="tel:+78002005400" rel="nofollow").text-primary.hover-color-positive.font-20.text-weight-bold.line-height-28 8 (800) 200-5-400
            .font-16.text-grey-9.q-ml-sm.q-mr-lg(v-if="!isActivAccessibility") ( с 8:00 до 22:00 по мск)
            base-btn.btn-call(v-if="!isActivAccessibility"
                width="180"
                backgroundHEX="#fbfafa"
                border="1px solid #0ca551"
                bradius="16"
                height="36"
                icon_color="positive"
                icon="side-contacts"
                label="Обратная связь"
                text_color="positive"
                icon_size="16px"
                @click="goChat"
            )
        .address-card.b-radius-16.bg-secondary.q-pa-lg(v-if="showHeader && !userAddress && userAuthorized && !adressDismissed")
            city-form(headerCard)
            .b-radius-8.bg-grey-5.q-mt-md.q-px-md.q-py-sm.text-primary
                .text-weight-bold.line-height-24
                    | Добавить пункт выдачи или адрес доставки?  
                .q-mt-xs.line-height-20
                    | Это поможет вам заранее увидеть условия доставки товаров
                .row.q-mt-xs
                    base-btn(
                        width="120"
                        height="30"
                        style="margin-right: 16px !important"
                        bradius="16"
                        font_size="13"
                        outline
                        color="secondary"
                        text_color="positive"
                        label="Не сейчас"
                        border="1px solid #0ca551"
                        @click="dismissAddress"
                    )
                    base-btn(
                        width="120"
                        height="30"
                        label="Добавить"
                        bradius="16"
                        font_size="13"
                        @click="showDeliveryAddress"
                    )
    q-toolbar.font-14.first-header.bg-positive
        .wrapper.row.justify-between.items-center
            .row
                city-form(@showDeliveryAddress="showDeliveryAddress" :adressDismissed="adressDismissed")
            q-space.space
            button-a(v-if="!isActivAccessibilityPanel")
            template(v-for="item in topMenu")
                router-link(:to="item.link" :title="item.title").font-16.text-weight-medium.line-height-22.text-secondary.first-header__link.top-menu
                    | {{ item.title }}


            //- router-link(
            //-     v-if="showAnalytics"
            //-     to="/"
            //- ).q-pl-lg.font-13.text-primary.hover-color-positive.hide_table
            //-     | Аналитика
    q-toolbar.d-none.d-sm-flex.items-center.third-header.q-pt-sm.q-pb-lg.column(
        :class="[!showHeader ? 'third-header__fix bg-secondary' : '', !showHeader && !prodPage ? 'third-header__fix-border' : '']")
        .wrapper 
            .row.no-wrap.items-end
                router-link.q-mr-md.third-header__avatar_link(:to="city ? `/${city.slug}/` : '/'" title="Главная")
                    q-avatar.cursor-pointer(square)
                        img(:src="logo" style="display: block !important;" alt="eTabl")
                        .font-12.logo-text
                            | онлайн аптека
                .flex.justify-end.items-center.q-mx-md
                    base-btn(
                        label="Каталог"
                        style="min-width: 140px;"
                        icon_size="xs"
                        font_size="16"
                        text_color="secondary"
                        icon_color="secondary"
                        height="44"
                        bradius="20"
                        :loading="catalogLoading"
                        loading_color="secondary"
                        background="bg-positive"
                        :icon="showList ? 'cross' : 'menu'"
                        @click="showList = !showList"
                        centred
                        outline)
                search.q-ml-md.q-mr-sm.third-header__search(:scrollHide="!showHeader")

                .relative-position
                    icon-block.cursor-pointer(
                        :link="profileLink"
                        icon="mobile_profile"
                        :title="userAuthorized ? 'Профиль' : 'Войти'"
                        :count="promocodesCount"
                        :badgePosition="[-4, -10]"
                        @mouseover="showArea = true"
                        @mouseleave="showArea = false"
                        @click.prevent.stop="!userAuthorized ? signIn() : null")
                        drop-down-menu(v-if="showArea && userAuthorized")

                .hide_tablet
                    icon-block(
                        :link="favLink"
                        icon="heart"
                        :title="'Избранное'"
                        :count="favCount"
                        :class="heartbass"
                        :badgePosition="[-4, 5]")

                icon-block(
                    v-if="userAuthorized && !countOfActiveOrders"
                    :link="city? `/${city.slug}/personal-area?tab=orders` : '/personal-area?tab=orders'"
                    icon="mobile_orders"
                    title="Заказы"
                    :count="countOfActiveOrders"
                    :badgePosition="[-4, -7]")
                icon-block(
                    v-if="userAuthorized && countOfActiveOrders"
                    :staticIcon="false"
                    :link="city? `/${city.slug}/personal-area?tab=orders` : '/personal-area?tab=orders'"
                    icon="box.gif"
                    title="Заказы"
                    :count="countOfActiveOrders"
                    size="24px"
                    :badgePosition="[-4, -7]")
                icon-block(
                    :link="city? `/${city.slug}/basket` : '/basket'"
                    icon="mobile_basket"
                    title="Корзина"
                    :count="basketCount")
            div(:class="showHeader ? 'q-mt-lg' : 'q-mt-sm'")
                fast-links
    category-list(
        target=".desktop-header > .q-toolbar:nth-of-type(2)"
        :value="showList"
        @hide="showList = false"
        :fixMenu="!showHeader")
    div(style="display: none;")
        ul
            template(v-for="item in newCategories" :key="item.code")
                router-link(:title="item.name" :to="city ? `/${city.slug}/catalog/${item.slug_name}` : `/catalog/${item.slug_name}`")
                    li {{ item.name }}
                ul
                    template(v-for="categories in item.categories" :key="categories.code")
                        router-link(:title="categories.name" :to="city ? `/${city.slug}/catalog/${categories.slug_name}` : `/catalog/${categories.slug_name}`")
                            li {{ categories.name }}
                        ul
                            template(v-for="subcategories in categories.subcategories" :key="subcategories.code")
                                router-link(:title="subcategories.name" :to="city ? `/${city.slug}/catalog/${subcategories.slug_name}` : `/catalog/${subcategories.slug_name}`")
                                    li {{ subcategories.name }}
q-dialog.modal-map(
   v-model="mapDialog" 
   @hide="closeMapDialog")
        q-icon.close-icon(
            name="etabl:cross"
            color="secondary"
            size="24px"
        )
        q-card.modal-map__body
            base-map(
                v-if="drugstores.length"
                :drugstores="drugstores"
                :city="city"
                :basket="false"
                address
                @setAddress="setAddress"
                @showDeliveryAddress="showDeliveryAddressOpened")
q-dialog(
    v-model="deliveryAddressDialog"
    @hide="closeDeliveryAddressDialog")
        delivery-address-choose(
            addressHeader
            :showAddressChoose="deliveryAddressDialog"
            :openForm="openForm"
            @showMap="showMapDialog"
            @close="closeAddressChoose"
            @hide="closeDeliveryAddressDialog"
        )
</template>

<script>
import { useQuasar, useMeta, LocalStorage } from 'quasar';
import { ref, computed, watch, onMounted, getCurrentInstance } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import md5 from 'src/assets/helpers/md5.js';
import { convertGender } from 'src/assets/helpers';

import buttonA from 'components/Accessibility/button.vue';
import CityForm from 'components/CityForm/CityForm.vue';
import BaseBtn from 'components/Base/BaseBtn.vue';
import CategoryList from 'components/CategoryList/CategoryList.vue';
import Search from 'components/Search/Search.vue';
import IconBlock from 'components/IconBlock/IconBlock.vue';
import DropDownMenu from 'components/DropDownMenu/DropDownMenu.vue';
import FastLinks from 'components/FastLinks/FastLinks.vue';
import BaseMap from 'components/Base/BaseMap.vue';
import DeliveryAddressChoose from 'components/BasketNew/DeliveryAddressChoose.vue';

export default {
    name: 'HeaderDesktop',
    emits: ['showHeader'],
    components: {
        CityForm,
        BaseBtn,
        CategoryList,
        Search,
        IconBlock,
        DropDownMenu,
        buttonA,
        FastLinks,
        BaseMap,
        DeliveryAddressChoose,
    },
    setup(props, { emit }) {
        // объект стора
        const store = useStore();
        const route = useRoute();
        const q = useQuasar();

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);

        const showHeader = ref(true);
        watch(showHeader, (value) => {
            if(!value && !adressDismissed.value)
                dismissAddress();
            emit('showHeader', !value);
        });
        const showList = ref(false);
        const showArea = ref(false);
        const fixPlash = ref(true);
        const showPlash = ref(false);
        const mapDialog = ref(false);
        const drugstores = ref([]);
        const deliveryAddressDialog = ref(false);
        const openForm = ref(false);
        const adressDismissed = ref(true);

        const headerHeight = computed(() => {
            return process.env.CLIENT
                ? document.querySelector('.first-header')?.offsetHeight + document.querySelector('.top')?.offsetHeight
                : 0;
        });

        const userAuthorized = computed(() => store.getters['user/USER_AUTHORIZED']);
        const userInfo = computed(() => store.getters['user/USER_INFO']);
        const countOfActiveOrders = computed(() => store.getters['user/USER_COUNT_ACTIVE_ORDERS']);
        const isActivAccessibility = computed(() => store.getters['styles/isActiv']);
        const isActivAccessibilityPanel = computed(() => store.getters['styles/isActivPanel']);

        // промокоды
        const promocodes = computed(() => store.getters['basketNew/PROMOCODES']);

        const favCount = computed(() => {
            return userAuthorized.value
                ? store.getters['user/USER_COUNT_FAVORITE_PRODUCTS']
                : process.env.CLIENT ? store.getters['localStorage/FAVOURITES_COUNT'] : 0;
        });
        const basketCount = computed(() => {
            return userAuthorized.value
                ? store.getters['basketNew/BASKET_COUNT']
                : process.env.CLIENT ? store.getters['localStorage/BASKET_COUNT'] : 0;
        });

        const promocodesCount = computed(() => {
            return userAuthorized.value
                ? promocodes?.value?.length
                : 0;
        });

        const heartbass = computed(() => favCount.value >= 1 ? 'heart' : '');

        const logo = computed(() => {
            return q.platform.is.desktop
                ? 'logo.svg'
                : 'logo_adaptive.svg';
        });

        const goScroll = () => {
            if (process.env.CLIENT) {
                if (scrollY > headerHeight.value) {
                    if (showHeader.value === true) {
                        showHeader.value = false;
                    }
                } else {
                    if (showHeader.value === false) {
                        showHeader.value = true;
                    }
                }

                if(scrollY > 104) {
                    if(fixPlash.value === true) {
                        fixPlash.value = false;
                    }
                } else {
                    if (fixPlash.value === false) {
                        fixPlash.value = true;
                    }
                }
            }
        };

        const slide = ref(1);

        const topMenu = [
            {
                title: 'Зарабатывайте с eTabl',
                link: city.value ? `/${city?.value?.slug}/partneram` : '/partneram',
            },
            {
                title: 'Проекты',
                link: city.value ? `/${city?.value?.slug}/projects` : '/projects',
            },
            {
                title: 'Адреса аптек',
                link: city.value ? `/${city?.value?.slug}/drugstores` : '/drugstores',
            },
            {
                title: 'Вопросы и ответы',
                link: city.value ? `/${city?.value?.slug}/faq` : '/faq',
            },
            {
                title: 'Доставка и оплата',
                link: city.value ? `/${city?.value?.slug}/shipping` : '/shipping',
            },
        ];

        const newCategories = computed(() => store.getters['categories/NCATEGORIES']);

        const catalogLoading = ref(false);

        const letsShowList = async () => {
            if(!newCategories.value) {
                catalogLoading.value = true;
                let newCategories = await store.dispatch('categories/GET_NCATEGORIES', false);
                store.commit('categories/SET_NCATEGORIES', newCategories);
                catalogLoading.value = false;
            };
        };

        const signIn = () => {
            try {
                ym(72234883,'reachGoal','klik-voyti');
            } catch {}
            store.commit('auth/SET_ENTRY_AUTH', 'signin');
        };

        const favLink = computed(() => {
            if(userAuthorized.value) {
                if(city.value) {
                    return `/${city.value.slug}/personal-area?tab=favourites`;
                } else {
                    return '/personal-area?tab=favourites';
                }
            } else {
                return '/favorites';
            }
        });

        const profileLink = computed(() => {
            if(userAuthorized.value) {
                if(city.value)
                    return `/${city.value.slug}/personal-area?tab=profile`;
                else
                    return '/personal-area?tab=profile';
            } else return null;
        });

        const homePage = computed(() => route.name == 'Home');

        const error404 = computed(() => route.name == '404');

        const prodPage = computed(() => route.name == 'Product');

        const goChat = async () => {
            const user_data = {
                name: userInfo.value?.name || 'Незарегистрированный пользователь',
                patronymic: userInfo.value?.patronymic || null,
                gender: userInfo.value?.gender || null,
                email: userEmail.value?.email_address || null,
                phone: userPhone.value?.phone_number || null,
            };

            if (process.env.CLIENT) {
                if (Object.keys(window.widget.userRegisterData).length === 0) {
                    const { origin: ip } = await getIp();

                    if (!userUid.value) {
                        await store.dispatch('user/SET_UID');
                    }

                    window.widget.setUserRegisterData({
                        'hash': md5(userUid.value).toLowerCase(),
                        'name': user_data.name,
                        'lastName': user_data.patronymic,
                        'gender': user_data.gender,
                        'email':user_data.email,
                    });

                    window.widget.setCustomData(`
                        ID пользователя: ${userUid.value}
                        IP пользователя: ${ip}
                        Имя: ${user_data.name || ''}
                        Отчество: ${user_data.patronymic || ''}
                        Пол: ${user_data.gender ? convertGender(user_data.gender) : ''}
                        Номер телефона: ${user_data.phone || ''}
                        E-mail: ${user_data.email || ''}
                    `);
                }

                if (userId.value && userId.value !== userUid.value) {
                    store.commit('user/SET_UID', userId.value);

                    window.widget.setUserRegisterData({
                        'hash': md5(userUid.value).toLowerCase(),
                        'name': user_data.name,
                        'lastName': user_data.patronymic,
                        'gender': user_data.gender,
                        'email':user_data.email,
                    });
                }

                window.widget.open();
            }
        };

        const getIp = async () => {
            return new Promise((resolve) => {
                fetch('https://httpbin.org/ip')
                    .then((response) => {
                        resolve(response.json());
                    })
                    .catch(() => {
                        resolve({
                            origin: 'Не удалось определить...',
                        });
                    });
            });
        };

        // E-mail пользователя
        const userEmail = computed(() => store.getters['user/USER_EMAIL']);

        // Телефона пользователя
        const userPhone = computed(() => store.getters['user/USER_PHONE']);

        // uid пользователя
        const userUid = computed(() => store.getters['user/USER_UID']);

        // id пользователя
        const userId = computed(() => store.getters['user/USER_ID']);

        const userCityId = computed(() => store.getters['user/USER_CITY_ID']);

        const userAddress = computed(() => store.getters['user/USER_ADDRESS']);
        
        const accept = () => {
            LocalStorage.set('USER_CITY_ID', userCityId.value);
            showPlash.value = false;
        };

        const showMapDialog = async () => {
            drugstores.value = await store.dispatch('drugstores/GET_ALL_DRUGSTORES');
            drugstores.value = drugstores.value.filter((item) => !item.isParcelAutomat);

            openForm.value = true;
            mapDialog.value = true;
        };
        

        const setAddress = async (drugstore) => {
            await store.dispatch('user/UPDATE_USER_DATA', {
                address: {
                    address_id: drugstore.id,
                },
            });

            if (process.env.CLIENT) 
                window.location.reload(true);

            mapDialog.value = false;
        };

        const showDeliveryAddress = async () => {
            await store.dispatch('basketNew/GET_ADDRESSES');
            mapDialog.value = false;
            deliveryAddressDialog.value = true;
        };

        const showDeliveryAddressOpened = async () => {
            await store.dispatch('basketNew/GET_ADDRESSES');
            mapDialog.value = false;
            deliveryAddressDialog.value = true;
            openForm.value = true;
        };

        const closeAddressChoose = async (value) => {

            await store.dispatch('user/UPDATE_USER_DATA', {
                address: {
                    address_id: value.id,
                    is_delivery_address: !value.isPickup,
                },
            });

            if (process.env.CLIENT) 
                window.location.reload(true);

            deliveryAddressDialog.value = false;
        };

        const closeMapDialog = () => {
            mapDialog.value = false;
            openForm.value = false;
        };

        const closeDeliveryAddressDialog = () => {
            deliveryAddressDialog.value = false;
            openForm.value = false;
        };

        const dismissAddress = () => {
            adressDismissed.value = true;
            LocalStorage.set('hidePlash', true);
        };

        watch(route, () => {
            if(!adressDismissed.value)
                dismissAddress();
        });

        onMounted(async () => {
            if(!LocalStorage.getItem('USER_CITY_ID')) {
                showPlash.value = true;
            }

            if(!LocalStorage.getItem('hidePlash')) {
                adressDismissed.value = false;
            };

            await letsShowList();

            if (process.env.CLIENT) {
                window.addEventListener('scroll', goScroll);
            }

            // если пользователь авторизован - получаем кол-во активных заказов
            if (userAuthorized.value) {
                await store.dispatch('user/USER_GET_COUNT_ACTIVE_ORDERS');
            }

            try {
                const u = 'https://www.artes-grp.ru/upload/crm/site_button/loader_1_w754r9.js';

                var s = document.createElement('script'); 
                
                s.async = true; 
                
                s.src = u + '?' + (Date.now() / 60000 | 0);

                var h = document.getElementsByTagName('script')[0];
                
                h.parentNode.insertBefore(s, h);

                if (process.env.CLIENT) {
                    window.addEventListener('onBitrixLiveChat', (event) => {
                        if (event.detail.widget) {
                            window.widget = event.detail.widget;

                            window.widget.buttonInstance.container.style.display = 'none';

                            if (window.mutateTemplateComponent) {
                                window.mutateTemplateComponent('bx-livechat-form-welcome', { template: '<div></div>' });
                            }
                        }
                    });
                }
            } catch (e) {}
        });

        useMeta(() => {
            return {
                script: {
                    ldJson: {
                        type: 'application/ld+json',
                        innerHTML:
                            `[{
                                "@context": "https://schema.org/",
                                "@type": "WPHeader",
                                "headline": "онлайн-аптека"
                            }`,
                    },
                },
            };
        });

        return {
            logo,
            slide,
            signIn,
            topMenu,
            userInfo,
            showList,
            showArea,
            favCount,
            heartbass,
            showHeader,
            promocodes,
            basketCount,
            letsShowList,
            userAuthorized,
            promocodesCount,
            countOfActiveOrders,
            newCategories,
            catalogLoading,
            city,
            favLink,
            profileLink,
            homePage,
            error404,
            isActivAccessibility,
            isActivAccessibilityPanel,
            goChat,
            fixPlash,
            showPlash,
            userCityId,
            accept,
            prodPage,
            mapDialog,
            drugstores,
            showMapDialog,
            setAddress,
            userAddress,
            deliveryAddressDialog,
            showDeliveryAddress,
            closeAddressChoose,
            openForm,
            closeMapDialog,
            closeDeliveryAddressDialog,
            dismissAddress,
            adressDismissed,
            showDeliveryAddressOpened,
        };
    },
};
</script>


<style lang="scss" scoped>
.desktop-header {
    position: relative !important;
    background-color: #fbfafa !important;
    .border-radius-16 {
        border-radius: 30px;
    }
    .q-toolbar {

        min-height: 64px;

        @media (max-width: 1280px){
            min-height: 44px;
        }
        @media (max-width: 960px) {
            min-height: 38px;
        }
        a {
            text-decoration: none;
        }
    }

    .first-header {
        &__link {
            @media screen and (min-width: 1280px) {
                padding: 0 8px;
            }

            @media screen and (min-width: 1440px) {
                padding: 0 16px;
            }
        }
        &__last-link {
            @media screen and (min-width: 1280px) {
                padding-left: 16px;
            }
        }
    }

    .second-header{
        font-weight: bold;

        a {
            color: $app-text-secondary;
            display: block;
        }
    }


    .third-header {
        a {
            color: $app-text-accent;
        }


        .wrapper {
            @media (max-width: 960px) {
                align-items: flex-end;
            }
        }

        &__fix {
            position: fixed;
            top: 0;
            height: 118px;
        }

        &__fix-border {
            border-bottom: 1px solid $grey-7;
        }

        &__icons {
            & > i {
                margin-bottom: 3px;
            }
        }
        &__search {
            @media (max-width: 1280px) {
                margin-left: 0px;
                margin-right: 0px;
            }
        }
        &__avatar_link {
            display: block;

            @media screen and (max-width: 960px) {
                margin: 0;
            }
        }
    }
}

.active-offers :deep {
    height: 33px !important;
    border-radius: 40px;

    .q-btn__wrapper {
        display: flex;
        align-items: center;
        padding: 0 16px !important;
        min-height: 100% !important;

        &::before {
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2) !important;
        }
    }

    .q-btn__content {
        text-transform: none !important;

        span {
            font-weight: 400;
            line-height: 22px;
        }

        i {
            margin-right: 15px;
        }
    }
}

.mobile-header {
    background-color: inherit;

    .q-toolbar {
        margin-top: 8px;
        background: none;
        backdrop-filter: blur(27.1828px) !important;
        border-radius: 0px 0px 0px 24px;

        @media screen and (max-width: $breakpoint-mobile) {
            backdrop-filter: none !important;
        }
    }
}

.q-avatar {
    width: 96px;
    height: 55px;

    @media (max-width: 960px) {
        width: 40px;
        height: 40px;
        margin: 0;
    }
}

.q-avatar :deep(.q-avatar__content) {
    overflow: unset !important;
}

.q-badge--floating {
    top: -9px;
    right: -5px;
}

.q-menu {
    box-shadow: none !important;
}

.hide_tablet {
    @media (max-width: 1280px) {
        display: none;
    }
}

.logo-text {
    font-family: "Montserrat";
    font-weight: 700;
    position: relative;
    bottom: 8px;
    left: 2px;
}

.space {
    @media screen and (max-width: 1280px) {
        display: none;
    }
}

.heart :deep {
    div {
        i {
            animation: heartbeat 1s infinite;
        }
    }
}

@keyframes heartbeat {
  0% {
    transform: scale( 1 );
  }
  20% {
    transform: scale( 1.1 )
      translateX(5%)
      translateY(5%);
  }
  40% {
    transform: scale( 1.2 )
      translateX(9%)
      translateY(10%);
  }
}

.q-carousel {
    height: initial;
    background-color: transparent;
    .q-carousel__slide {
        padding: 5px 0 0;
        img {
            border-radius: 30px;
        }
    }
}
.top {
    height: 68px;
}
.top-menu {
    border: 1px solid transparent;
}
.top-menu.router-link-exact-active {
    border: 1px solid #fff;
    border-radius: 30px;
}

.btn-call :deep(.btn__label) {
    padding-left: 8px;
    width: 82%;
}

.btn-call :deep(.col-sm-3) {
    width: 18%;
}

.btn-call :deep(.col-12) {
    align-self: baseline;
}

.address-card {
    width: 500px;
    height: 190px;
    top: 124px;
    position: fixed;
    z-index: 9999;
}

.modal-map {
  &__body {
      max-width: 100% !important;
      width: 90vw !important;
      height: 90vh !important;

      @media screen and (max-width: $breakpoint-mobile) {
          width: 100vw !important;
          height: 89vh !important;
      }
  }
}

.close-icon {
    position: absolute;
    right: 16px;
    top: 16px;
}
</style>
