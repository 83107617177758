<template lang="pug">
.wrapper.row.justify-between.items-center.relative-position
    router-link.link.row.q-px-md.q-py-sm.text-primary.fast-link(
        v-for="item in links"
        :to="city ? `/${city.slug}${item.link}` : item.link"
        :title="`${item.title}`")
        q-icon(
            :name="`etabl:${item.icon}`"
            color="primary"
            size="sm"
        ).q-mr-xs
        .font-16.q-ml-sm
            | {{ item.title }}
</template>

<script>
import { useQuasar } from 'quasar';
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default {
    name: 'FastLinks',
    setup() {
        const store = useStore();
        const route = useRoute();
        const q = useQuasar();

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);

        const links = [
            {
                title: 'Витамины и микроэлементы',
                link: '/catalog/vitaminy-i-mikroelementy=000000016',
                icon: 'vitamins',
            },
            {
                title: 'Простуда и грипп',
                link: '/catalog/prostuda-i-gripp=000000169',
                icon: 'virus',
            },
            {
                title: 'Красота и уход',
                link: '/catalog/krasota-i-uхod=000000199',
                icon: 'beauty',
            },
            {
                title: 'Медицинские приборы',
                link: '/catalog/pribory-medicinskie=000000346',
                icon: 'devices',
            },
            {
                title: 'Оптика',
                link: '/catalog/optika=000000569',
                icon: 'optics',
            },
            {
                title: 'Ортопедия',
                link: '/catalog/ortopediya=000000496',
                icon: 'ortoped',
            },
        ];

        return {
            links,
            city,
        };
    },
};
</script>

<style lang="scss" scoped>

.fast-link {
    &:hover {
        border-radius: 16px;
        background: $secondary;
        color: $positive !important;
    }
}
</style>
